import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";
// import required modules
import { Pagination } from "swiper";

const HeroSlider = ({ latestProjects, heroSliderTitle }) => {
  // eslint-disable-next-line no-console
  // console.log(latestProjects);

  const paginationItems = latestProjects.map((project, i) => {
    return (`
      <div key=${project.id}>
        <h1>0${i + 1}</h1>
        <div class="latestProjectDetails">
          <h5>${project.data.project_type}</h5>
          <h5>${project.data.project_location}</h5>
        </div>
      </div>
    `)
  });

  const pagination = {
    clickable: true,
    renderBullet(index, className) {
      return `<span class="${className}">${paginationItems[index]}<h6 class="d-none heroSliderTitle">${heroSliderTitle}</h6></span>`;
    },
  };


  return (
    <div className="heroSliderContainer">
      <Swiper
        pagination={pagination}
        modules={[Pagination]}
        className="heroSliderSwiper"
      >
        {latestProjects.map((project) => {
          return (
            <SwiperSlide key={project.id}>
              <GatsbyImage
                image={project.data.main_image.gatsbyImageData}
                alt={project.data.main_image.alt || "Project Image"}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default HeroSlider;
