import React from "react";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const Services = ({ serviceTabs, sectionHeader, smallTitle }) => {
  // console.log(serviceTabs);
  return (
    <Container>
      <Tab.Container id="serviceTabs-tabs" defaultActiveKey="assetManagement">
        <Row>
          <Col md={5} className="p-0">
            <Tab.Content>
              <Tab.Pane eventKey="assetManagement">
                <GatsbyImage
                  image={serviceTabs[0].service_image.gatsbyImageData}
                  alt={serviceTabs[0].service_image.alt || "Sevice Image"}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="realEstate">
                <GatsbyImage
                  image={serviceTabs[1].service_image.gatsbyImageData}
                  alt={serviceTabs[1].service_image.alt || "Sevice Image"}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col md={7} className="servicesInfoContainer ps-5">
            <h6 className="text-primary mb-4">{smallTitle}</h6>
            <h3 className="mb-4">{sectionHeader}</h3>
            <Nav variant="pills" className="flex-column text-info mb-3">
              <Nav.Item className="mb-3">
                <Nav.Link eventKey="assetManagement">
                  <h4>
                    <span className="me-2">01.</span>{" "}
                    {serviceTabs[0].services_tab_title}
                  </h4>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="realEstate">
                  <h4>
                    <span className="me-2">02.</span>{" "}
                    {serviceTabs[1].services_tab_title}
                  </h4>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="assetManagement">
                <p className="text-light mb-5">
                  {serviceTabs[0].tab_description}
                </p>
                <Anchor
                  className="mb-4 afterLinkDash"
                  href={serviceTabs[0].tab_link.url}
                >
                  {serviceTabs[0].tab_link_title}
                </Anchor>
              </Tab.Pane>
              <Tab.Pane eventKey="realEstate">
                <p className="text-light mb-5">
                  {serviceTabs[1].tab_description}
                </p>
                <Anchor
                  className="mb-4 afterLinkDash"
                  href={serviceTabs[1].tab_link.url}
                >
                  {serviceTabs[1].tab_link_title}
                </Anchor>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default Services;
