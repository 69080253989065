import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { GatsbyImage } from "gatsby-plugin-image";
import Anchor from "../elements/Anchor";

const LatestNews = ({ sectionHeader, latestArticles }) => {
  return (
    <Container>
      <Row className="d-flex justify-content-between align-items-center">
        <Col sm={6} as="h3">{sectionHeader.section_header}</Col>
        <Col sm={6} className="text-sm-end mt-3 mt-sm-0">
          <Anchor
            href={sectionHeader.articles_link.url}
            className="afterLinkDash "
          >
            {sectionHeader.articles_link_title}
          </Anchor>
        </Col>
      </Row>
      <Row className="latestNewsContainer mt-3">
        {latestArticles.map((article) => {
          return (
            <Col key={article.id} md={4} className="latestNewsCard my-3">
              <div className="card-front">
                <GatsbyImage
                  image={article.data.main_image.gatsbyImageData}
                  alt={article.data.main_image.alt || "Article Image"}
                />
              </div>
              <div className="card-back bg-dark p-3">
                <p>{article.data.date}</p>
                <h4 className="mb-4 font-weight-bold">{article.data.title}</h4>
                <p className="text-light">{article.data.main_text}</p>
                <Anchor className="afterLinkDash" href="/">
                  {sectionHeader.article_card_link_text}
                </Anchor>
              </div>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default LatestNews;
