import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Anchor from "../elements/Anchor";

const AboutHome = ({ aboutHome }) => {
  return (
    <Container className="mt-lg-0">
      <Row className="d-flex flex-column-reverse flex-md-row">
        <Col
          md={5}
          className="d-flex flex-column justify-content-center pe-lg-5 mt-4"
        >
          <h6 className="text-primary mb-4">{aboutHome.small_title}</h6>
          <h3 className="mb-5">{aboutHome.section_header}</h3>
          <p className="text-light mb-5">{aboutHome.description}</p>
          <Anchor className="mb-4" href={aboutHome.link.url}>
            <span className="afterLinkDash">{aboutHome.link_title}</span>
          </Anchor>
        </Col>
        <Col md={7} className="p-0">
          <GatsbyImage
            image={aboutHome.about_home_image.gatsbyImageData}
            alt={aboutHome.about_home_image.alt || "about us image"}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default AboutHome;
