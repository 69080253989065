import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/common/Layout";
import HeroSlider from "../components/homepage/Heroslider";
import AboutHome from "../components/homepage/AboutHome";
import Services from "../components/homepage/Services";
import LatestProjects from "../components/homepage/LatestProjects";
import LatestNews from "../components/homepage/LatestNews";
import { constructActiveDoc } from "../utilities/helpers";

const IndexPage = ({ data }) => {
  if (!data) return null;

  return (
    <Layout
      settings={data.settings}
      activeDocMeta={constructActiveDoc(data.page)}
    >
      <HeroSlider
        latestProjects={data.latestProjects.nodes.slice(0, 3)}
        heroSliderTitle={data.page.data.hero_slider_title}
      />
      <AboutHome aboutHome={data.page.data.about_home[0]} />
      <Services
        serviceTabs={data.page.data.services_home}
        sectionHeader={data.page.data.services_section_header}
        smallTitle={data.page.data.services_small_title}
      />
      <LatestProjects
        sectionHeader={data.page.data.latest_projects[0]}
        latestProjects={data.latestProjects.nodes.slice(3, 11)}
      />
      <LatestNews
        sectionHeader={data.page.data.latest_articles[0]}
        latestArticles={data.latestArticles.nodes}
      />
    </Layout>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    latestProjects: allPrismicProject(
      filter: { lang: { eq: $lang } }
      sort: { fields: data___date, order: DESC }
      limit: 11
    ) {
      nodes {
        alternate_languages {
          uid
          type
          lang
        }
        lang
        type
        url
        id
        data {
          main_image {
            alt
            gatsbyImageData
          }
          project_type
          project_title
          project_location
          project_category
          main_text
        }
      }
    }
    latestArticles: allPrismicArticle (
      filter: { lang: { eq: $lang } }
      sort: { fields: data___date, order: DESC }
      limit: 3
    ) {
      nodes {
        uid
        id
        type
        lang
        alternate_languages {
          uid
          type
          lang
        }
        url
        data {
          date
          title
          main_text
          main_image {
            alt
            gatsbyImageData
          }
        }
      }
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      lang
      type
      url
      alternate_languages {
        lang
        type
        uid
      }
      data {
        hero_slider_title
        about_home {
          small_title
          section_header
          link_title
          link {
            url
          }
          description
          about_home_image {
            alt
            gatsbyImageData
          }
        }
        services_small_title
        services_section_header
        services_home {
          tab_link_title
          tab_link {
            url
          }
          tab_description
          services_tab_title
          service_image {
            alt
            gatsbyImageData
          }
        }
        latest_projects {
          section_header
          projects_link_title
          projects_link {
            url
          }
          no_filters
          filters_category_2
          filters_category_1
          project_card_link_text
        }
        latest_articles {
          article_card_link_text
          section_header
          articles_link_title
          articles_link {
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
