import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
import { Pagination } from "swiper";
import Anchor from "../elements/Anchor";

const LatestProjects = ({ sectionHeader, latestProjects }) => {
  return (
    <div className="latestProjectsContainer">
      <Container>
        <Row className="d-flex justify-content-between align-items-center">
          <Col lg={6} as="h3">
            {sectionHeader.section_header}
          </Col>
          <Col sm={6} lg={3} className="latestFilters mt-3 mt-lg-0">
            <Anchor className="me-3" href="/">
              {sectionHeader.no_filters}
            </Anchor>
            <Anchor className="text-white me-3" href="/">
              {sectionHeader.filters_category_1}
            </Anchor>
            <Anchor className="text-white" href="/">
              {sectionHeader.filters_category_2}
            </Anchor>
          </Col>
          <Col sm={6} lg={3} className="text-sm-end mt-3 mt-lg-0">
            <Anchor
              href={sectionHeader.projects_link.url}
              className="afterLinkDash me-4"
            >
              {sectionHeader.projects_link_title}
            </Anchor>
          </Col>
        </Row>
      </Container>
      <Container
        fluid
        className="latestProjectsSliderContainer py-5 px-0 px-sm-4"
      >
        <Swiper
          spaceBetween={25}
          slidesPerView={1}
          slidesPerGroup={1}
          breakpoints={{
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            // when window width is >= 992px
            992: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
            1400: {
              slidesPerView: 4,
              slidesPerGroup: 4,
            },
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {latestProjects.map((project) => {
            return (
              <SwiperSlide key={project.id}>
                <div className="card-front">
                  <GatsbyImage
                    image={project.data.main_image.gatsbyImageData}
                    alt={project.data.main_image.alt || "project main image"}
                  />
                  <h4>{project.data.project_title}</h4>
                </div>
                <div className="card-back bg-primary text-dark d-flex px-4 py-5 flex-column align-items-start justify-content-between">
                  <h4>{project.data.project_title}</h4>
                  <p>{project.data.main_text}</p>
                  <div>
                    <StaticImage
                      src="../../images/sima-black.png"
                      alt="footer logo"
                    />
                  </div>
                  <Anchor href="/" className="text-dark">
                    {sectionHeader.project_card_link_text}
                  </Anchor>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Container>
    </div>
  );
};

export default LatestProjects;
